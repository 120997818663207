<template>
  <div class="view pa24">
    <el-button type="primary" @click="$router.push({ path: '/addSpike' })">新增</el-button>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column prop="killNo" align="center" show-overflow-tooltip label="秒杀编号" />
        <el-table-column prop="type" align="center" label="活动类型">
          <template slot-scope="scope">
            {{ scope.row.killType == 1 ? "限时秒杀" : "促销秒杀" }}
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="秒杀商品" />
        <el-table-column prop="minKillPrice" align="center" label="秒杀金额">
          <template slot="header">
            秒杀金额
            <el-tooltip class="item" effect="dark" content="展示该商品下最低的秒杀金额" placement="top">
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ (scope.row.minKillPrice / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="minPrice" align="center" label="原价金额">
          <template slot="header">
            原价金额
            <el-tooltip class="item" effect="dark" content="展示该商品下最低的原价" placement="top">
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ (scope.row.minPrice / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="maxPurchase" align="center" label="单账号最多可购买数" />
        <el-table-column align="center" label="剩余商品库存">
          <template slot="header">
            剩余商品库存
            <el-tooltip class="item" effect="dark" content="展示该商品下的所有库存之和" placement="top">
              <i style="font-size: 16px" class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.stockNum - scope.row.goodsNum }}
          </template>
        </el-table-column>
        <el-table-column prop="frequency" width="250" align="center" label="秒杀时间">
          <template slot-scope="scope">
            <div>起 ：{{ setGetDataTimeSec(scope.row.startTime) }}</div>
            <div>止 ：{{ setGetDataTimeSec(scope.row.endTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $router.push({
                  path: `/addSpike?id=${scope.row.killId}&drafts=1`,
                })
              "
              >编辑</el-button
            >
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteDemandSquare(scope.row)" icon="el-icon-info" icon-color="red" title="是否删除该条秒杀数据，删除后不可恢复。">
              <el-button class="ml10" slot="reference" type="text" style="color: #fd634e">
                删除
              </el-button>
            </customPopconfirm>
            <customPopconfirm v-if="drafts" onConfirm-button-text="确定" cancel-button-text="取消" @confirm="publish(scope.row.killId, scope.row.goodsId)" icon="el-icon-info" icon-color="red" title="确认发布该秒杀活动吗？">
              <el-button class="ml10" type="text" slot="reference">
                发布
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getGoodsInfoPCShop, delKillGoodsInfo, releaseKillGoods, selectKillGoodsList } from "@/api/marketingActivities";
import { getDataTimeSec } from "@/utils";
export default {
  name: "list",
  components: {
    commonTable,
    customPopconfirm,
  },
  props: {
    drafts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  created() {
    this.getGoodsInfoPCShop();
  },
  computed: {
    setGetDataTimeSec() {
      return val => getDataTimeSec(val);
    },
  },
  methods: {
    async getGoodsInfoPCShop() {
      let data = {
        draftType: this.drafts ? 0 : 1,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      };
      data["isKill"] = 1;
      // try {
      this.loading = true;
      const result = await selectKillGoodsList(data);
      this.loading = false;
      this.tableData = result?.data?.pageInfo?.list;
      this.total = result?.data?.pageInfo.list.length;

      console.log("this.tableData", this.tableData);
      // } catch (error) {
      //   this.loading = false;
      // }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGoodsInfoPCShop();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGoodsInfoPCShop();
    },
    /**@method 删除 */
    async deleteDemandSquare(row) {
      await delKillGoodsInfo({ killId: row.killId });
      this.getGoodsInfoPCShop();
    },
    /**@method 发布 */
    async publish(killId, goodsId) {
      try {
        await releaseKillGoods({ killId, goodsId });
        this.getGoodsInfoPCShop();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
